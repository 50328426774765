@import "./widgets/range-slider.css";
@import "./widgets/input.css";
@import "./widgets/event.css";
@import "./widgets/identity.css";
@import "./widgets/participants.css";
@import "./widgets/feedback.css";
@import "./widgets/best-results.css";
@import "./widgets/settings.css";
@import "./widgets/history.css";
@import "./widgets/banner.css";
@import "./widgets/tooltip.css";
@import "./animate.css";

input {
  padding: 1em;
  font-size: 1em;
  color: var(--primaryGrayscaleColor);
  background: var(--secondaryDark);
  border-width: 0 0 2px 0;
}

.button {
  cursor: pointer;
  font-size: 1rem;
  background: var(--primaryColor);
  color: var(--primaryGrayscaleColor);
  border: none;
  border-radius: 0 4px 4px 0;
  padding: 8px 5px;
}

.button:hover {
  background: var(--secondaryColor);
}

.button:focus-visible {
  background: var(--secondaryColor);
}

.block {
  margin-bottom: 2em;
}

/* dots */
.dots {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  position: relative;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: var(--primaryGrayscaleColor);
  border-radius: 50%;
  display: inline-block;
  margin: 2px;
}

/* pencil */
.pencil {
  width: 0.25em;
  height: 1em;
  background: var(--secondaryGrayscaleColor);
  position: relative;
  transform: rotate(30deg);
  max-height: 0.58em;
  margin-right: 0.75em;
  margin-top: 0.2em
}
.pencil::before {
  content: "";
  position: absolute;
  width: 0.25em;
  height: 0.2em;
  background: var(--secondaryGrayscaleColor);
  top: -0.25em;
}
.pencil::after {
  content: "";
  position: absolute;
  width: 0.2em;
  height: 0.2em;
  background: var(--secondaryGrayscaleColor);
  bottom: -0.1em;
  transform: rotate(45deg);
}
