.banner {
  background: rgba(255, 222, 84, 0.92);
  width: 100%;
  padding: 2em;
  color: var(--primaryDark);
  font-size: 1.25em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.banner button {
  margin-left: 1em;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
}

.calendar .banner {
  padding: 0.5em 1em;
  margin-top: 1em;
}

.landing-banner p {
  display: flex;
  align-items: center;
}

.landing-banner p b {
  margin-right: .25em;
}

.landing-banner .logo {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin: 0 0.25em 0 0;
}

@media (max-width: 600px) {
  .landing-banner {
    display: none;
  }
}