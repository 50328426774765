.input-widget .input-widget-form {
  display: flex;
  justify-content: space-between;
}

.input-widget input {
  font-size: 1.1em;
  padding: 0.25em 0.5em;
  z-index: 1;
}

.input-widget .displayed-value {
  display: flex;
  align-items: center;
}

.input-widget .displayed-value:hover {
  cursor: pointer;
}

.input-widget .displayed-value h3 {
  margin: 0;
}

.input-widget .displayed-value .value {
  display: flex;
  align-items: center;
}
