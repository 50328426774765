.calendar {
    display: flex;
    flex-direction: column;
    height: fit-content;
    user-select: none;
    justify-content: center;
    align-items: center;
    padding: 1em 0;
    color: var(--primaryGrayscaleColor);
    background-color: var(--primaryDark);
    width: 100%;
}

.calendar .calendar-columns {
    display: flex;
    flex-wrap: wrap;
}

.calendar .day {
    min-width: 7em;
}

.calendar .day.disabled {
    opacity: .5;
}

.calendar .cell {
    height: 3em;
    width: available;
    margin: 0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--secondaryDark);
}

.calendar .half-hour-intervals .cell {
    height: 2.3em;
}

.calendar .title {
    min-width: 7em;
    background: rgba(66, 68, 90, 0.075);
    font-weight: bold;
    cursor: pointer;
}

.calendar .time-slot {
    border: 2px solid transparent;
    border-radius: 0.15em;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    position: relative;
}

.calendar .time-slot:hover {
    background: var(--tertiaryDark)
}

.calendar .time-slot.half-hour {
    color: var(--secondaryGrayscaleColor);
    background: var(--tertiaryDark);
}

.calendar .time-slot .dots {
    position: absolute;
    top: 2px;
    left: 2px;
}

.calendar .half-hour-intervals .dots {
    top: 0px;
    left: 0px;
}

.calendar .day .time-slot.selected {
    background: var(--tertiaryColor);
    border: 2px solid rgba(0, 0, 0, 0.075);
    color: var(--primaryDark);
    font-weight: bold;
}

.calendar .day .time-slot.selected.match {
    background: var(--primaryComplementaryColor);
}

.calendar .time-slot.selected .dot {
    background-color: var(--secondaryDark);
}

.calendar .selection-area {
    background: rgba(46, 115, 252, 0.11);
    border: 1px solid rgba(98, 155, 255, 0.85);
    border-radius: 0.15em;
}

@media (max-width: 600px) {
    .calendar {
        margin-bottom: 0;
    }
}
