.multi-range, .single-range {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.multi-range .controls, .single-range .controls {
    position: relative;
    flex: 1;
    height: fit-content;
    display: flex;
    align-items: center;
    width: 100%;
}

.multi-range .values, .single-range .values {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: .5em;
}

.multi-range input[type=range], .single-range input[type=range] {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    margin: 0;
    padding: 0 2px;
    overflow: hidden;
    outline: none;
    background: linear-gradient(grey, grey) no-repeat center;
    background-size: 100% 2px;
    pointer-events: none;
    position: absolute;
}

.multi-range input[type=range].upper {
    background: none;
}

.multi-range input[type=range]::-webkit-slider-thumb,
.single-range input[type=range]::-webkit-slider-thumb {
    height: 1em;
    width: 1em;
    border-radius: 28px;
    background-color: #fff;
    margin: 5px 0;
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    pointer-events: auto;
}

.multi-range input[type=range]::-moz-range-thumb,
.single-range input[type=range]::-moz-range-thumb {
    border-radius: 28px;
    border: none;
    pointer-events: auto;
    cursor: pointer;
}

.multi-range input[type=range]::-ms-thumb,
.single-range input[type=range]::-ms-thumb {
    cursor: pointer;
    pointer-events: auto;
    position: relative;
}

.multi-range input[type="range"]::-webkit-slider-thumb:active,
.singe-range input[type="range"]::-webkit-slider-thumb:active {
    transform: scale(1.6);
    border: 2px solid var(--primaryDark);
}

.multi-range input[type="range"]::-moz-range-thumb:active,
.single-range input[type="range"]::-moz-range-thumb:active {
    transform: scale(1.2);
    border: 2px solid var(--primaryDark);
}

.multi-range .range-value,
.single-range .range-value {
    margin: 0 5px;
    height: 2em;
    font-size: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondaryGrayscaleColor);
}

.range-value .past-date {
    /* TODO: find better color */
    color: #9d7676;
}
